.About {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-content {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #0056b3;
    margin-top: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .About {
      padding: 10px;
    }
  
    .about-content {
      padding: 15px;
    }
  }