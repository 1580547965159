.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .privacy-policy h1 {
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .privacy-policy h2 {
    color: #333;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .privacy-policy p, .privacy-policy ul {
    color: #666;
    line-height: 1.6;
  }
  
  .privacy-policy ul {
    padding-left: 2rem;
  }
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 1rem;
    }
  }