.TermsOfUse {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .terms-content {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #0056b3;
    margin-top: 20px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  .last-updated {
    font-style: italic;
    color: #6c757d;
  }
  
  @media (max-width: 768px) {
    .TermsOfUse {
      padding: 10px;
    }
  
    .terms-content {
      padding: 15px;
    }
  }