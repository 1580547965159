.App {
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

main {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .App {
    max-width: 90%;
    padding: 20px;
  }

  main {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .App {
    max-width: 1200px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

@media (max-width: 768px) {
  main {
    margin-top: 60px; /* Adjust this value based on header height */
  }
}