.consultancy-jobs-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .consultancy-jobs-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .job-count {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .loading-spinner,
  .error-message {
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
  
  .error-message {
    color: #ff0000;
  }
  
  @media (max-width: 768px) {
    .consultancy-jobs-container {
      padding: 10px;
    }
  
    .consultancy-jobs-title {
      font-size: 20px;
    }
  
    .job-count {
      font-size: 14px;
    }
  }