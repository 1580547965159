/* Contact.css */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  background-color: #f4f4f4;
  padding: 1rem;
}

.contact-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 100%;
  max-width: 800px;
}

h1 {
  color: #007bff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.contact-form {
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  resize: vertical;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.contact-info {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
}

.contact-info h2 {
  color: #007bff;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.info-item i {
  color: #007bff;
  margin-right: 0.75rem;
  width: 20px;
  text-align: center;
}

.info-item p {
  margin: 0;
}

.office-hours {
  font-style: italic;
  color: #666;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 0.5rem;
  }

  .contact-content {
    padding: 1rem;
  }

  h1 {
    font-size: 1.25rem;
  }

  .contact-info h2 {
    font-size: 1.1rem;
  }
}