.JobCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .JobCard:hover {
    transform: translateY(-3px);
  }
  
  .job-info h3 {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }
  
  .job-info p {
    margin-bottom: 4px;
    font-size: 0.9rem;
  }
  
  .company {
    font-weight: bold;
    color: #007bff;
  }
  
  .location, .salary, .deadline {
    color: #666;
  }
  
  .calendar-icon {
    margin-right: 5px;
  }
  
  .job-tags {
    margin-top: 10px;
  }
  
  .tag {
    display: inline-block;
    background-color: #f0f0f0;
    color: #333;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .JobCard {
      padding: 10px;
    }
  
    .job-info h3 {
      font-size: 1rem;
    }
  
    .job-info p {
      font-size: 0.8rem;
    }
  
    .tag {
      font-size: 0.7rem;
      padding: 1px 6px;
    }
  }