.job-details {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .job-details.loaded {
    opacity: 1;
  }
.JobView {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .back-button {
    display: inline-block;
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
  }
  
  .JobView h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .JobView .company {
    font-weight: bold;
    color: #007bff;
    font-size: 1.2rem;
  }
  
  .JobView .location,
  .JobView .salary,
  .JobView .deadline {
    margin-bottom: 10px;
  }
  
  .JobView .calendar-icon {
    margin-right: 5px;
  }
  
  .JobView h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .JobView ul {
    padding-left: 20px;
  }
  
  .JobView .apply-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    text-decoration: none;
  }
  
  .JobView .apply-btn:hover {
    background-color: #0056b3;
  }

  /* ... (previous styles remain the same) ... */

.share-buttons {
    margin-top: 30px;
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .share-buttons h4 {
    margin-bottom: 10px;
  }
  
  .share-btn {
    margin-right: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .email-btn {
    background-color: #D44638;
    color: white;
  }
  
  .twitter-btn {
    background-color: #1DA1F2;
    color: white;
  }
  
  .facebook-btn {
    background-color: #4267B2;
    color: white;
  }
  
  .linkedin-btn {
    background-color: #0077B5;
    color: white;
  }
  
  .share-btn:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .share-buttons {
      display: flex;
      flex-wrap: wrap;
    }
  
    .share-btn {
      margin-bottom: 10px;
      flex: 1 0 calc(50% - 10px);
    }
  }

  /* ... (previous styles remain the same) ... */


  
  .share-buttons h4 {
    margin-bottom: 10px;
  }
  
  .share-btn {
    margin-right: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .email-btn {
    background-color: #D44638;
    color: white;
  }
  
  .x-btn {
    background-color: #000000;
    color: white;
  }
  
  .facebook-btn {
    background-color: #4267B2;
    color: white;
  }
  
  .linkedin-btn {
    background-color: #0077B5;
    color: white;
  }
  
  .share-btn:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .share-buttons {
      display: flex;
      flex-wrap: wrap;
    }
  
    .share-btn {
      margin-bottom: 10px;
      flex: 1 0 calc(50% - 10px);
    }
  }

  /* ... fix share button on mobile  ... */

.share-buttons {
    margin-top: 30px;
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .share-buttons h4 {
    margin-bottom: 15px;
  }
  
  .share-btn {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .email-btn {
    background-color: #D44638;
    color: white;
  }
  
  .x-btn {
    background-color: #000000;
    color: white;
  }
  
  .facebook-btn {
    background-color: #4267B2;
    color: white;
  }
  
  .linkedin-btn {
    background-color: #0077B5;
    color: white;
  }
  
  .share-btn:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .share-buttons {
      text-align: center;
    }
  
    .share-btn {
      display: inline-block;
      width: calc(50% - 10px);
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
  }
  
  @media (max-width: 480px) {
    .share-btn {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .back-link {
    display: inline-block;
    margin-bottom: 20px;
    padding: 5px 10px;
    background: none;
    border: none;
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }

  