/* Header.css */
.Header {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(206, 17, 38, 0.1) 33%,
    rgba(0, 102, 0, 0.1) 66%
  );
  padding: 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  flex: 0 0 auto;
}

.logo img {
  max-height: 50px;
  width: auto;
}

.nav-malawi {
  flex: 1;
}

.nav-malawi ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
}

.nav-malawi li {
  margin: 0 0.5rem;
}

.nav-malawi a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-malawi a:hover {
  color: #ce1126;
}

.category-filter select,
.language-switcher select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.search-bar form,
.mobile-search-bar form {
  display: flex;
}

.search-bar input,
.mobile-search-bar input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
}

.search-bar button,
.mobile-search-bar button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.mobile-controls {
  display: none;
}

body {
  transition: padding-top 0.3s ease;
}

body.menu-open {
  padding-top: var(--menu-height);
}

@media (max-width: 768px) {
  .Header {
    padding: 0.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
  }

  .header-content {
    flex-wrap: nowrap;
    align-items: center;
  }

  .logo {
    flex: 0 0 auto;
    margin-right: 0.5rem;
  }

  .logo img {
    max-height: 40px;
  }

  .mobile-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  .mobile-search-bar {
    flex: 1;
    margin-right: 0.5rem;
  }

  .mobile-search-bar form {
    display: flex;
  }

  .mobile-search-bar input {
    flex: 1;
    min-width: 0;
  }

  .mobile-search-bar button {
    padding: 8px;
  }

  .mobile-menu-toggle {
    display: block;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
  }

  .nav-malawi {
    display: none;
    width: 100%;
    background: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .nav-malawi.mobile-open {
    display: block;
  }

  .nav-malawi ul {
    flex-direction: column;
    align-items: stretch;
    padding: 0.5rem;
  }

  .nav-malawi li {
    margin: 0.5rem 0;
  }

  .category-filter,
  .language-switcher,
  .search-bar {
    width: 100%;
  }

  .category-filter select,
  .language-switcher select {
    width: 100%;
    margin-top: 0.5rem;
  }

  .search-bar {
    display: none;
  }

  /* Styling for mobile filter options */
  .category-filter select,
  .language-switcher select {
    appearance: none;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 8px 10px;
  }

  .category-filter select:focus,
  .language-switcher select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}