.footer {
    background-color: #2c3e50; /* Darker background */
    padding: 2rem 0;
    color: #ecf0f1; /* Light text color for contrast */
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 1rem;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #3498db; /* Accent color for headings */
  }
  
  .footer-section p {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #bdc3c7; /* Slightly muted text color */
  }
  
  .footer-section nav {
    display: flex;
    flex-direction: column;
  }
  
  .footer-section nav a {
    color: #bdc3c7; /* Slightly muted text color */
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .footer-section nav a:hover {
    color: #3498db; /* Accent color on hover */
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons a {
    color: #bdc3c7; /* Slightly muted icon color */
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #3498db; /* Accent color on hover */
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #34495e; /* Slightly lighter than the background for subtle separation */
    font-size: 0.8rem;
    color: #bdc3c7; /* Slightly muted text color */
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-section {
      margin-bottom: 2rem;
    }
  
    .social-icons {
      justify-content: center;
    }
  }

  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons a {
    color: #bdc3c7; /* Slightly muted icon color */
    font-size: 1.5rem; /* Adjust size as needed */
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #3498db; /* Accent color on hover */
  }