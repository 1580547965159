/* FAQs.css */
.faqs-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  h1 {
    color: #007bff;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faq-item {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .faq-question {
    width: 100%;
    text-align: left;
    padding: 1rem;
    background-color: #f8f9fa;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question:hover {
    background-color: #e9ecef;
  }
  
  .faq-question.active {
    background-color: #007bff;
    color: white;
  }
  
  .faq-icon {
    font-size: 1.5rem;
    line-height: 1;
  }
  
  .faq-answer {
    padding: 1rem;
    background-color: white;
    border-top: 1px solid #e0e0e0;
  }
  
  @media (max-width: 768px) {
    .faqs-container {
      padding: 1rem;
    }
  }