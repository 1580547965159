.not-found {
    text-align: center;
    padding: 50px 20px;
  }
  
  .not-found h1 {
    font-size: 48px;
    color: #007bff;
  }
  
  .not-found p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .home-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }