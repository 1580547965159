:root {
  --background-color: #F5F5F5;
  --text-color: #333333;
  --card-background: #FFFFFF;
  --accent-color: #007bff;
  --border-color: #E0E0E0;
}

.JobList {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.JobList h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--accent-color);
}

.job-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.job-link {
  text-decoration: none;
  color: inherit;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.job-link:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.load-more-container {
  text-align: center;
  margin-top: 30px;
  grid-column: 1 / -1;
}

.load-more-btn {
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-btn:hover {
  background-color: #0056b3;
}

.error, .no-jobs {
  text-align: center;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  grid-column: 1 / -1;
}

@media (max-width: 767px) {
  .job-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .job-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .JobList h2 {
    text-align: left;
  }

  .job-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}